import * as React from 'react';
import SEO from '../components/Utilities/SEO/SEO';

function IndexPage() {
  return (
    <div>
      <SEO />
    </div>
  );
}

export default IndexPage;
